import {useToast} from 'vue-toastification/composition'
import {ref} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default function useSettingApi() {
    const toast = useToast()
    const appStoreAccounts = ref(null)
    const androidAccounts = ref(null)
    const refIosStoreAccountListTable = ref(null)
    const refAndroidStoreAccountListTable = ref(null)
    const isLoading = ref(true)

    const refreshData = () => {
        fetchAndroidAccounts()
        fetchAppstoreAccounts()
    }

    const fetchAppstoreAccounts = () => {
        store.dispatch('storeAccountStore/fetchAppstoreAccounts').then(response => {
            const {data} = response
            appStoreAccounts.value = data;
        }).catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Oops! Something went wrong!',
                },
            })
        })
    }
    const fetchAndroidAccounts = () => {
        store.dispatch('storeAccountStore/fetchAndroidAccounts').then(response => {
            const {data} = response
            androidAccounts.value = data;
        }).catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Oops! Something went wrong!',
                },
            })
        })
    }

    return {
        toast,
        isLoading,
        refreshData,
        refIosStoreAccountListTable,
        refAndroidStoreAccountListTable,
        appStoreAccounts,
        androidAccounts,
        fetchAndroidAccounts,
        fetchAppstoreAccounts
    }
}
